import React from 'react';
import logo from './logo.png';


class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {secciones: ['Home', 'Nosotros', 'Servicios','Contacto']};
  }
  
  AddSeccion (seccion){
    this.setState({comment: seccion});
  };
  
   render() {
   return (
 
    <div className="Navbar">
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
         <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
         </button>
        <ul className="navbar-brand ml-auto">
            <a className="navbar-brand" href="https://www.learndisruptive.com" target="_blank" rel="noopener noreferrer">
                <img src={logo} className="App-logo" alt="logo" width="100" height="100" class="img-thumbnail align-top" /> 
            </a>
         </ul>
         <div className="collapse navbar-collapse" id="navbarCollapse">
         <ul className="navbar-nav mr-auto">
             <li className="nav-item active">
                <a className="nav-link" href=".">{this.state.secciones[0]}<span className="sr-only">(current)</span></a>
             </li>
             <li className="nav-item">
                <a className="nav-link" href=".">{this.state.secciones[1]}</a>
             </li>
             <li className="nav-item">
                <a className="nav-link" href=".">{this.state.secciones[2]}</a>
             </li>
             <li className="nav-item">
                <a className="nav-link" href=".">{this.state.secciones[3]}</a>
             </li>
         </ul>
         <form className="form-inline mt-2 mt-md-0">
             <input className="form-control mr-sm-2" type="text" placeholder="Buscar" aria-label="Buscar"/>
             <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Buscar</button>
         </form>
         </div>
         </nav>
    </div>
   );
    
  }
 
}
 
export default Navbar;