import React from 'react';
//import logo from './logo.svg';
import Navbar from './components/navbar/navbar.js';
//import Saludo from './components/saludo/saludo.js';
import Sendsms from './components/sendsms/sendsms.js';
import Sendmail from './components/sendmail/sendmail.js';
import './App.css';
import { withAuthenticator, AmplifySignIn, AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from '@aws-amplify/ui-react';

function App() {
  return (
    <AmplifyAuthenticator>
      <AmplifySignIn headerText="Prueba SignIn" slot="sign-in" ></AmplifySignIn>
      <AmplifySignUp headerText="Prueba SingUp" slot="sign-up" ></AmplifySignUp>
        <div>
        <div className="App">
          <header className="App-header">
            <Navbar/>
            </header>
        <body className="App-body">
          <Sendsms/>
          <Sendmail/>
        </body>
      </div>
      <AmplifySignOut></AmplifySignOut>
    </div>
  </AmplifyAuthenticator>
  );
}

export default App;

//export default withAuthenticator(App, {initialAuthState: 'signup'});

/*

function Game() {
  return (
    <div className="game">
      <header className="game-info">
        <navbar/>
      </header>
    </div>
  );
}
*/


