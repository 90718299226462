import React from 'react';
import logo_sms from './sendsms.svg';
import '../../App.css';


class Sendsms extends React.Component {
  constructor(props) {
    super(props);
  }
  
   render() {
   return (
 
    <div class="card">
      <img src={logo_sms} class="card-img-top" alt="Imagen de envio de SMSs en formato SVG"/>
      <div class="card-body">
        <hr></hr>
        <h3 class="card-title">Envio SMS</h3>
        <h5><p class="card-text">Funcionalidad para el envio de campañas por SMS.</p></h5>
        <a href="." class="btn btn-primary">Inicio</a>
      </div>
    </div>
   );
    
  }
 
}
 
export default Sendsms;

/*
         <img src={logo} class="card-img-top" alt="logo"/>
*/
         